import React from 'react';
import { 
  Container, 
  Typography, 
  Button, 
  Box,
  Card, 
  CardContent,
  IconButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import MessageIcon from '@mui/icons-material/Message';
import PersonIcon from '@mui/icons-material/Person';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import MapIcon from '@mui/icons-material/Map';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import { auth } from '../firebase';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useState(auth.currentUser);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const features = [
    {
      title: 'Messages',
      icon: <MessageIcon />,
      action: () => navigate('/messages')
    },
    {
      title: 'Travel Matches',
      icon: <GroupIcon />,
      action: () => navigate('/matches')
    },
    {
      title: 'Trip Planning',
      icon: <MapIcon />,
      action: () => navigate('/trips')
    },
    {
      title: 'Photo Gallery',
      icon: <PhotoCameraIcon />,
      action: () => navigate('/photos')
    }
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to TravelsBud
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Explore your travel dashboard and connect with fellow travelers
        </Typography>
      </Box>

      <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: 'repeat(4, 1fr)' }, gap: 3 }}>
        {features.map((feature, index) => (
          <Card 
            key={index}
            sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column',
              cursor: 'pointer',
              '&:hover': {
                boxShadow: 6
              }
            }}
            onClick={feature.action}
          >
            <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
              <IconButton 
                size="large" 
                sx={{ mb: 2 }}
                color="primary"
              >
                {feature.icon}
              </IconButton>
              <Typography gutterBottom variant="h6">
                {feature.title}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSignOut}
          startIcon={<SettingsIcon />}
        >
          Sign Out
        </Button>
      </Box>
    </Container>
  );
};

export default Dashboard; 