import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  List, 
  ListItem, 
  ListItemButton,
  ListItemText, 
  ListItemAvatar, 
  Avatar, 
  Divider 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Messages: React.FC = () => {
  const navigate = useNavigate();

  const mockChats = [
    { id: 1, name: 'Sarah', lastMessage: 'Hey, are you still in Paris?', time: '2m ago' },
    { id: 2, name: 'John', lastMessage: 'The weather is great here in Rome!', time: '1h ago' },
    { id: 3, name: 'Emma', lastMessage: 'Let\'s meet up in Barcelona!', time: '3h ago' },
  ];

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 4 }}>
          Messages
        </Typography>

        <List>
          {mockChats.map((chat, index) => (
            <React.Fragment key={chat.id}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate(`/chat/${chat.id}`)} sx={{ py: 2 }}>
                  <ListItemAvatar>
                    <Avatar>{chat.name[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={chat.name}
                    secondary={chat.lastMessage}
                    primaryTypographyProps={{ fontWeight: 'medium' }}
                  />
                  <Typography variant="caption" color="text.secondary">
                    {chat.time}
                  </Typography>
                </ListItemButton>
              </ListItem>
              {index < mockChats.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default Messages; 